<template>
  <div class="home">
    <HomePage/>
  </div>
</template>

<script>
import HomePage from '@/components/HomePage.vue'

export default {
  name: 'HomeView',
  components: {
    HomePage
  }
}
</script>

<style scoped>
  .home {
    display: flex;
    align-items: center;
    justify-content: center; 
  }
</style>