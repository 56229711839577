<template>
  <NotFound class="quatrozeroquatro"/>
</template>

<script>

import NotFound from '@/components/NotFoundComponent.vue'

export default {
  components: {
    NotFound
  }
}
</script>

<style>
  .quatrozeroquatro {
    display: flex;
    align-items: center;
    justify-content: center; 
  }
</style>