<template>
  <div>
    <canvas id="three"></canvas>
    <div class="container d-flex flex-column">
      <div id="main" class="text-center">
        <h1 id="titulo">Reset Marketing</h1>
        <h2 class="sub-titulo">Em construção...</h2>
        <h3 class="sub-titulo glitching"><GlitchText texto="Sometimes a reset is needed"/></h3>
      </div>
    </div>
  </div>
</template>

<script>

import GlitchText from '@/components/GlitchTextComponent.vue'

export default {
  components: {
    GlitchText
  },
  mounted() {
    console.log('Running');
    window.onresize = function () {
      canvas.width = screen.width;
      canvas.height = screen.height;
      drawArcs();
    }
    
    var canvas = document.getElementById('three');
    let context = canvas.getContext('2d');

    canvas.width = screen.width;
    canvas.height = screen.height;

    function drawArcs() {
      // set size

      let num = 15;
      let BgRadius = screen.height * 0.3;
    
      for(let i = 0; i < num; i++) {
        context.save();
        context.translate(canvas.width, canvas.height);
        context.scale((screen.height > 914) ? i : i / 2 , 1)
        context.beginPath();
        context.strokeStyle = `white`;
        context.arc(0,0, BgRadius + (i), 0, Math.PI * 2);
        context.stroke();
        context.restore();
      }
    }


    drawArcs();
  }
}
</script>

<style scoped>
  .glitching {
    border: 1px solid #313131;
    background: rgba(0,0,0,1);
    box-shadow: black 0px 5px 15px;
    font-family: 'Press Start 2P', sans-serif;
    padding: 5px;
  }

  #three {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  #main {
  }

  #titulo {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 2000;
    letter-spacing: 2px;
    font-size: 6em;
  }

  .sub-titulo {
    font-family: 'Roboto', sans-serif;
  }


</style>