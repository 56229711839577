<template>
  <div class="container text-center">
    <div class="test">
      <h1 id="mainTitle">404</h1>
      <h2 id="sub">Page not Found</h2>
      <hr>
      <router-link to="/"><GlitchButton texto="Voltar Tela Inicial"/></router-link>
    </div>

    

  </div>
</template>

<script>

import GlitchButton from '@/components/GlitchButtonComponent.vue';

export default {
  components: {
    GlitchButton
  }
}
</script>

<style scoped>

  #element {
    color: #313131;
  }

  
  #mainTitle {
    position: relative;
    font-weight: 700;
    font-size: 10em;
    letter-spacing: 0.05em;
    text-shadow: var(--primaria) 0 7px 7px;
  }
  
  #sub {
    margin-top: -20px;
    font-size: 2.9em;
  }

  /* media queries */

  @media (min-width: 730px) {
    #sub {
      margin-top: -30px;
      font-size: 5em;
    }
  }
  @media (min-width: 1000px){
    #mainTitle {
      font-size: 20em !important;
    }
  }



  a {
    text-decoration: none;
  }

</style>