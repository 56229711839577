import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// add bootstrap
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.min.js"

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
