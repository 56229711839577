<template>
  <!-- navbar -->
  <nav class="navbar navbar-dark navbar-expand-sm  justify-content-center">
    <div class="container d-flex flex-column">
      <ul class="navbar-nav navbar-collapse collapse justify-content-center" id="navbarToggleExternalContent">
        <li class="nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/contato" class="nav-link disabled">Contato</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/links" class="nav-link">Links Diretos</router-link>
        </li>
        <li class="nav-item ">
          <router-link to="/servicos" class="nav-link disabled">Serviços</router-link>
        </li>
        <li class="nav-item nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Ferramentas
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="/#" class="dropdown-item disabled">Visualizar SVG</router-link>
              <router-link to="/#" class="dropdown-item disabled">Rastreio Correios</router-link>
            </li>
          </ul>
        </li>
      </ul>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
}
</script>

<style scoped>
  nav {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    /*clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);*/
  }
</style>