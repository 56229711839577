<template>
  <div class="links">
    <Links/>
  </div>
</template>

<script>

import Links from '@/components/LinksPage.vue'
export default {
  name: 'LinksView',
  components: {
    Links
  }
}
</script>
