<template>
  <div id="app">
    <NavBar/>
    <router-view id="element"/>
    <Footer/>
  </div>
</template>

<script>

import NavBar from '@/components/NavBarComponent.vue';
import Footer from '@/components/FooterComponent.vue';
export default {
  name: 'app',
  components: {
    NavBar,
    Footer
  }
}
</script>
<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white !important;
  background-color: black !important;
  background-image: url('@/assets/filmburn50.png'), url('@/assets/border-shadow_10.png'), url('@/assets/lights_1.png'), url('@/assets/lights_0_50.png');
  background-attachment: fixed;
  background-size: 90% 50%, cover, cover, cover, cover;
  background-repeat: no-repeat;
}

#app {
  position: relative;
  min-height: 100vh; /* 56px = cabecalho */
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  #app {
    min-height: calc(100vh - 56px); /* 56px = cabecalho */
  }
}

#element { 
  flex-grow: 1;
}

a {
  text-decoration: none;
}

:root {
  --primaria: #22b4b1;
}

</style>
