import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LinksPage from '../views/LinksPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/links',
    name: 'links',
    component: LinksPage
  },
  { 
    path: "*",
    name: '404', 
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
