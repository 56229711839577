<template>
  <div class="content">
    <a class="testing" :data-text="texto">{{texto}}</a>
  </div>
</template>

<script>
export default {
  props: {
    texto: String
  }
}
</script>

<style scoped>

a {
  display: block;
  color: white;
  text-decoration: none;
}

.testing {
    display: block;
    position: relative;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    padding: 15px 30px;
    font-size: .75em;
    letter-spacing: .35em;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid rgba(34, 180, 177,.7);
    transition: all .2s ease;
    margin: 0 auto;
    width: 300px;
    font-weight: 700;
}

.testing:before, 
.testing:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 0;
    width: 100%;
    -webkit-clip: rect(0px, 0px, 0px, 0px);
    clip: rect(0px, 0px, 0px, 0px);
    background: var(--primaria);
    color: white;
}

.testing:before {
    left: -3px;
    top: -2px;
    text-shadow: 2px 0 var(--primaria);
    box-shadow: 2px 0 var(--primaria);
}

.testing:after {
    left: 2px;
    bottom: -2px;
    text-shadow: -1px 0 var(--primaria);
    box-shadow: -1px 0 var(--primaria);
}

.testing:hover {
    background: var(--primaria);
    color: white;
}

.testing:hover:before {
    animation: glitch-test 1.5s infinite linear alternate-reverse;
}

.testing:hover:after {
    animation: glitch-test 2s infinite linear alternate;
}

@keyframes glitch-test {
  0% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  5.88235% {
    clip: rect(0px, 600px, 0px, 0px);
  }
  11.76471% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  17.64706% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  23.52941% {
    clip: rect(100px, 600px, 100px, 0px);
  }
  29.41176% {
    clip: rect(0px, 600px, 600px, 0px);
  }
  35.29412% {
    clip: rect(100px, 600px, 0px, 0px);
  }
  41.17647% {
    clip: rect(0px, 600px, 600px, 0px);
  }
  47.05882% {
    clip: rect(100px, 600px, 0px, 0px);
  }
  52.94118% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  58.82353% {
    clip: rect(100px, 450px,100px, 0px);
  }
  64.70588% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  70.58824% {
    clip: rect(100px, 450px, 100px, 0px);
  }
  76.47059% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  82.35294% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  88.23529% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  94.11765% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  100% {
    clip: rect(0px, 450px, 0px, 0px);
  }
}


</style>