<template>
  <div class="links">
    <main class="container text-center">
      <a href="/" id="resetLogo"><svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1529.6 478.11"><defs></defs><path class="logo" d="M1458.58,0V129.38H1320.46v70.07h138.12v104.3q0,43.32-31.68,73.81-31.14,31-75.39,31H1105.44a101.37,101.37,0,0,1-61-19.79A98.31,98.31,0,0,1,1007,339.05h63.65v-.54h209.13V303.75c0-48.14-12.65-89.14-46.89-123q-50.82-51.34-123-51.35H676.07c-28.88,0-52.55,10.16-73.23,30.49q-30.48,31-30.48,74.34c0,28.89,13.5,53.49,33.83,73.81,20.68,20.33,41,30.49,69.88,30.49H815.14a34.71,34.71,0,0,1,34.77,34.77,35.34,35.34,0,0,1-10.17,25.14,33.47,33.47,0,0,1-24.6,10.16H384.71a101.4,101.4,0,0,1-61-19.79c-17.83-12.48-25.85-29.06-33-49.74H354.4v-.54H563.54V303.75c0-48.14-13.77-89.14-48-123q-50.82-51.34-123-51.35c-2.36,0-4.68.05-7,.13v-.13H182.18c-48.14,0-93.79,17.12-128,51.35C20.28,214.61,0,255.61,0,303.75L.66,478.11H70.2l-.67-174.36c0-28.89,13.69-53.49,34.37-73.82,20.33-20.32,49.39-30.48,78.28-30.48h70.07c-23.72,29.94-37.45,64.7-37.45,104.3,0,48.13,12.47,89.32,46.35,123.55q51.35,50.82,123.56,50.81H815.14q43.32,0,74.35-30.48,30.48-31,30.48-74.35t-30.48-73.81q-31-30.5-74.35-30.49H676.07c-9.62,0-13.54-3.38-20.67-10.16a34.87,34.87,0,0,1,0-49.21c7.13-6.77,11.05-10.16,20.67-10.16H965.46q-34.38,44.91-34.38,104.3,0,72.19,50.81,123.55,51.35,50.82,123.55,50.81h246.07q73.76,0,126.19-50.81,51.91-51.35,51.9-123.55V0ZM453.49,218.7A101.3,101.3,0,0,1,490.93,269H294.1a101.24,101.24,0,0,1,37.44-50.28,106.2,106.2,0,0,1,122,0Zm717.39,0c17.83,12.84,25.84,29.6,33,50.28H1007a101.18,101.18,0,0,1,37.44-50.28c18.18-12.84,43-19.25,65.44-19.25A103.23,103.23,0,0,1,1170.88,218.7Z"/></svg></a>
      <hr>

      <div class="row text-center">
        <div class="col-sm-12 col-md mb-3">
          <div class="card">

            <a href="https://api.whatsapp.com/send?phone=5588997975529&text=ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os.">
              <div class="card-body overflow-hidden position-relative">
                <div class="wave"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
              </div>
            </a> 
          </div>
        </div>

        <div class="col-sm-12 col-md mb-3">
          <div class="card">
            <a href="https://www.behance.net/resetmkt">
              <div class="card-body overflow-hidden position-relative">
                <div class="wave"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/></svg>
                
              </div>
            </a>
          </div>
        </div>

        <div class="col-sm-12 col-md mb-3">
          <div class="card">
            <a href="https://www.instagram.com/reset.mkt/">
              <div class="card-body overflow-hidden position-relative">
                <div class="wave"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <hr>
    </main>

  </div>
</template>

<script>
export default {
  created: function() {
  }
}
</script>

<style scoped>

  hr {
    color: white;
    border: 1px solid white;
  }
  svg {
    fill: #3a3a3a
  }

  .card {
    border-radius: 10px 40px 5px 5px;
    background-color: transparent;
  }

  .card:hover {
    background-color: #3a3a3a;
    transform:scale(1.03);
    transition: background-color 2s;
    transition: transform 1s;
  }

  .card:not(:hover) {
    transition: all 1s;
  }

  .card:hover svg {
    transition: all 1s;
    fill: white;
  }
  .card:hover .wave:before {
    transition: all 1s;
    top: 30px;
  }

  .wave:before {
    content: "";
    position: absolute;
    width: 590px;
    height: 590px;
    border-radius: 220px;
    background-color: #22b4b1;
    top: 190px;
    left: 50%;
    transform: translate(-50%);
    animation: wave 5s infinite linear;
    transition: all 1s;
    z-index: -1;
  }

  .wave:hover:before {
    top: 15px;
  }

  @keyframes wave {
    0% {
      transform: translate(-50%) rotate(-180deg); 
    }

    100% {
      transform: translate(-50%) rotate(360deg);
    }
  }

  #resetLogo > svg {
    max-width: 400px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    fill: #707070;
  }
  
</style>